/* eslint-disable react/no-array-index-key */
import React from 'react'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Components
import ButtonDefault from 'components/elements/Button/ButtonDefault'

// Third Party
import styled from 'styled-components'

const TeamWrapper = styled.section`
  background: ${(props) => props.theme.color.face.secondary};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @media (max-width: 991px) {
      text-align: center;
    }
    @media (min-width: 992px) {
      line-height: 45px;
    }
  }
`

const StyledImage = styled(Plaatjie)`
  width: 175px;
  border-radius: 12px;
  box-shadow: 12px 11px 16px -6px #949494;

  & img {
    object-fit: contain !important;
    border-radius: 12px;
  }

  @media (max-width: 576px) {
    max-width: 140px;
  }
`

const Content = styled(ParseContent)`
  margin-top: 15px;
  @media (max-width: 991px) {
    text-align: center;
  }
`

const CustomColumn = styled.div`
  @media (min-width: 1200px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TeamWithEmployees
}

const TeamWithEmployees: React.FC<TeamProps> = ({ fields }) => (
  <TeamWrapper>
    <div className="container pb-3">
      <div className="row">
        <div className="col-12 mb-5">
          <ParseContent content={fields.title} />
        </div>
      </div>
      <div className="row">
        {fields?.teamMember?.map((edge, index: number) => (
          <React.Fragment key={index}>
            <CustomColumn className="mb-5 d-flex align-items-center justify-content-center col-6 col-md-4 col-lg-3">
              <div>
                <StyledImage
                  image={edge?.image}
                  alt=""
                  className="mx-lg-auto"
                />
                <Content key={index} content={edge?.name} />
              </div>
            </CustomColumn>
          </React.Fragment>
        ))}
      </div>
      <div className="row">
        <ButtonDefault
          className="mx-auto mb-5"
          to={fields?.link?.url || '/404'}
        >
          {fields?.link?.title}
        </ButtonDefault>
      </div>
    </div>
  </TeamWrapper>
)

export default TeamWithEmployees
